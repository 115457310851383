// Here you can add other styles
html { font-size: 16px; overflow-x: hidden; }
input, select, textarea {
  font-size: 16px !important;
}

.container-fluid {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.breadcrumb {
  margin-bottom: 0.75rem;
}

@media (pointer:none), (pointer:coarse) {
  .container-fluid {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    padding-right: 5px;
    padding-left: 5px;
  }
  .card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.0rem;
  }
  .breadcrumb {
    margin-bottom: 0.10rem;
  }
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.25) !important;
  z-index: 1050 !important;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}


.btn-outline-edit:hover {
  color: #fff;
  background-color: #aaaaaa;
  border-color: #333333; }
.btn-outline-edit:focus, .btn-outline-edit.focus {
  box-shadow: 0 0 0 2px rgba(115, 115, 115, 0.5); }
.btn-outline-edit.disabled, .btn-outline-edit:disabled {
  color: #6d6d6d;
  background-color: transparent; }
.btn-outline-edit:active, .btn-outline-edit.active,
.show > .btn-outline-edit.dropdown-toggle {
  color: #fff;
  background-color: #6d6d6d;
  border-color: #6d6d6d; }

.btn-outline-delete {
  color: #000;
  background-image: none;
  background-color: #FADBD8;
  border-color: #cc9999;
  border-radius: 5px;
}
.btn-outline-delete:hover {
  color: #fff;
  background-color: #F1948A;
  border-color: #333333; }
.btn-outline-delete:focus, .btn-outline-delete.focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.14);
  background-color: #f1948a;
}btn-outline-delete.disabled, .btn-outline-delete:disabled {
  color: #f86c6b;
  background-color: transparent; }


.btn-outline-default {
  color: #000;
  background-image: none;
  background-color: #e5e5ff;
  border-color: #e5e5ff;
  border-radius: 5px;
}
.btn-outline-default.active:hover {
  color: #fff;
  background-color: #aaaaff;
  border-color: #333333; }
.btn-outline-default:focus, .btn-outline-default.focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.14);
  background-color: #aaaaff;
}
.btn-outline-default:focus, .btn-outline-default.focus {
  box-shadow: 0 0 0 2px rgba(90, 90, 180, 0.5); }
.btn-outline-default.disabled, .btn-outline-default:disabled {
  color: #6d6d6d;
  background-color: #ccccff; }

.btn-outline-move {
  color: #000;
  background-image: none;
  background-color: #e5e5ff;
  border-color: #9999cc;
  border-radius: 5px;
}
.btn-outline-move:hover {
  color: #fff;
  background-color: #c5c5ff;
  border-color: #333333; }
.btn-outline-move:focus, .btn-outline-move.focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.14);
  background-color: #c5c5ff;
}
.btn-outline-move.disabled, .btn-outline-move:disabled {
  color: #aaaaaa;
  background-color: transparent; }

.btn-disabled {
  pointer-events: none;
}

div.bcwarningtext {
  color: #ff3333;
  font-weight: bold;
}
div.bcsuccesstext {
  color: #339933;
  font-weight: bold;
  opacity: 1;
}

.c-sidebar-brand {
  background: rgba(255, 255, 255, 1.0) !important;
}


.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 48px;
  margin-top: -48px; /* Half the height */
  margin-left: -68px;
}

.c-main {
  padding-top: 5px !important;
}

.smallicon {
  width: 128px;
  height: 128px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.c-sidebar {
  background: #0c2b44 !important;
}

.table {
  color: #000000 !important;
}

.tableiconcell td{
  padding:0px !important;
  vertical-align:top;
}


#hideContainer {
  overflow:hidden;
  position:relative;
}
#hideDiv {
  -webkit-animation: cssAnimation 3s forwards;
  animation: cssAnimation 3s forwards;
}
@keyframes cssAnimation {
  0%   {opacity: 1;}
  90%  {opacity: 1;}
  100% {opacity: 0;}
}
@-webkit-keyframes cssAnimation {
  0%   {opacity: 1;}
  90%  {opacity: 1;}
  100% {opacity: 0;}
}

._loading_overlay_overlay {
  background: rgba(0, 0, 100, 0.1) !important;
}

.notif_page {
  border-collapse: separate;
  border-spacing: 10px;
}

.notif-container {
  position:relative;
  background-color: #eeeeee;
  display: table-cell;
  border: 1px solid #666666;
  text-align: center;
  vertical-align: middle;
  width: 90px;
  height: 90px;
  cursor: pointer;
}

.notif-container-active {
  position:relative;
  background-color: #eeaaaa;
  display: table-cell;
  border: 3px solid #666666;
  text-align: center;
  vertical-align: middle;
  width: 90px;
  height: 90px;
  cursor: pointer;
}

.notif-btn {
  position: relative;
  top: 5px;
  margin: 0 auto;
  background-color: rgba(0,0,0, 0.3);
  width: 48px;
  height: 48px;
  border: 0px;
  color: #cccccc;
  padding: 5px 0;
  text-align: center;
}

.notif-img {
  width: 48px;
  height: 48px;
  opacity: 1;
  transition:
    opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  -webkit-transition: opacity 0.1s ease-in-out;
}

.notif-container:hover img {opacity: 0.5;}
